// 职位页面tag
const positionTag = {
  addPosition: 'company.position.add',
  editPosition: 'company.position.edit',
  delPosition: 'company.position.delete'
}
// 菜单、actions 导入导出权限
const menuImport = {
  importMenu: 'usermenu.import',
  exportMenu: 'usermenu.export',
  importActions: 'userRight.import',
  exportActions: 'userRight.export'
}
const permissions = {
  positionTag,
  menuImport
}
export default permissions
