<template>
  <div class="app-container">
    <el-form :model="searchForm" size="small" ref="searchForm" inline>
      <el-form-item label="职位等级名称">
        <el-input v-model="searchForm.uplLevel" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="searchData">查询</el-button>
        <el-button size="small" @click="onClear">清空</el-button>
      </el-form-item>
      <!-- 操作按钮通过延迟加载，来匹配tag数据的promise请求 -->
      <el-button type="success" size="small" @click="addClick" v-if="hasTag"  v-permission="permissions.addPosition">添加职级</el-button>
    </el-form>

    <el-table
      v-loading="isLoading"
      :data="dataList"
      border
      height="calc(100% - 120px)"
      style="width: 100%"
      size="small"
      :header-cell-style="{textAlign:'center', background: '#41A1FF', color: '#ffffff'}">
      <el-table-column type="index" label="序号" align="center"></el-table-column>
      <el-table-column prop="uplLevel" label="职位等级" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="编辑" placement="top" v-permission="permissions.editPosition">
            <i class="table_icons el-icon-edit" @click="editClick(scope.row)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top" v-permission="permissions.delPosition">
            <i class="table_icons el-icon-delete" @click="deletePosition(scope.row.uplId)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align: center;margin-top:2em">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <!-- 编辑 添加弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="editVisible"
      width="500px"
      :close-on-click-modal="false"
      v-ismove>
      <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" size="small">
        <el-form-item label="职位等级名称" prop="uplLevel">
          <el-input v-model="editForm.uplLevel" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="editVisible = false">取 消</el-button>
        <el-button v-loading="saveLoading" v-if="title === '添加职位'" type="primary" size="small" @click="addPosition('editForm')">添加</el-button>
        <el-button v-loading="saveLoading" v-else type="primary" size="small" @click="editPosition('editForm')">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getPositionLevelPage, deletePositionLevel, addPositionLevel, editPositionLevel } from '@/api/position-level'
import { required } from '@/utils/validate'
import permissions from '@/utils/permissionsData'

export default {
  data() {
    return {
      isLoading: false,
      searchForm: {
        uplLevel: ''
      },
      dataList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      editVisible: false,
      editForm: {
        uplLevel: ''
      },
      editFormRules: {
        uplLevel: [required]
      },
      title: '',
      saveLoading: false,
      permissions: permissions.positionTag
    }
  },
  mounted() {
    this.getListPosition()
  },
  computed: {
    hasTag() {
      return this.$store.getters.permissionTag
    }
  },
  methods: {
    getListPosition() {
      this.searchForm.pageNum = this.pageNum
      this.searchForm.pageSize = this.pageSize
      this.isLoading = true
      getPositionLevelPage(this.searchForm).then(response => {
        if (response.code === '000000' && response.data.list) {
          this.isLoading = false
          this.dataList = response.data.list
          this.total = response.data.total
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    // 清空
    onClear() {
      this.searchForm = {}
      // this.searchForm = this.$options.data().searchForm
      this.searchData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getListPosition()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getListPosition()
    },
    searchData() {
      this.pageNum = 1
      this.getListPosition()
    },
    deletePosition(id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true
          deletePositionLevel(id).then(response => {
            if (response.code === '000000') {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.searchData()
            } else {
              this.$message.error('删除失败')
            }
          }).catch(() => {
            this.isLoading = false
          })
        })
        .catch(() => {
          this.isLoading = false
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    addClick() {
      this.title = '添加职位'
      this.editVisible = true
      this.editForm.uplLevel = ''
    },
    editClick(row) {
      this.title = '职位编辑'
      this.editVisible = true
      this.editForm.uplLevel = row.uplLevel
      this.editForm.uplId = row.uplId
    },
    // 修改职位
    editPosition(editForm) {
      this.$refs[editForm].validate(valid => {
        if (valid) {
          this.saveLoading = true
          editPositionLevel(this.editForm)
            .then(response => {
              this.saveLoading = false
              if (response.code === '000000') {
                this.searchData()
                this.editVisible = false
                this.$message({
                  message: '编辑成功~',
                  type: 'success'
                })
              }
            })
            .catch(error => {
              this.saveLoading = false
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 新增职位
    addPosition(editForm) {
      this.$refs[editForm].validate(valid => {
        if (valid) {
          this.saveLoading = true
          addPositionLevel(this.editForm)
            .then(response => {
              this.saveLoading = false
              this.editVisible = false
              this.searchData()
              this.$message({
                message: '添加成功~',
                type: 'success'
              })
            })
            .catch(error => {
              this.saveLoading = false
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style>

</style>
